/*

  File: content/markdownFragments.js
  Kind: Function
  Description: Get a fragment from allMarkdownRemarkNodes for a spcific fragment ID (caller should have limited to page)
*/


const PrepareFragments = (fragments) => {

  const Fragments = fragments.nodes
  
  
  const FragmentsCat = Fragments.reduce((a,c) => {
    a[c.fields.locator.fragment] = {
      body: c.body,
      images: c.frontmatter && c.frontmatter.images && c.frontmatter.images.reduce((a,c)=>{
        a[c.name] = c;
        return a;
      },{})
    };
    return a;
  },{});

  return FragmentsCat;

};

export default PrepareFragments;

    
